<template>
    <div class="flex w-full flex-col gap-8 self-stretch text-xl lg:w-1/2">
        <div class="flex flex-col gap-4">
            <h1 class="leading-none">
                {{ $t('review.averageRating') }}
            </h1>
            <div class="flex gap-2">
                <StarRating v-if="reviews()?.reviewStats?.averageRating" :rating="reviews().reviewStats.averageRating" />
                <span class="font-bold">{{ reviews()?.reviewStats?.averageRating! }}</span>
            </div>
            <span v-if="reviews()?.reviewStats?.totalReviews">
                {{ $t('review.onTheBasisOf') }} {{ reviews()?.reviewStats?.totalReviews }} {{ $t('review.reviews') }}.
            </span>
            <div v-for="item in sortedRatingPercentages" :key="item?.rating!" class="flex items-center gap-4">
                <span class="flex items-center gap-x-2">
                    <label class="w-4 font-bold">{{ item?.rating }}</label>
                    <Icon :icon="IconStar" class="fill-star-filled text-star-filled" :auto-align="false" />
                </span>
                <progress
                    v-if="item?.percentage! > 0"
                    aria-labelledby="rating total"
                    class="block h-3 w-full overflow-hidden bg-primary-200 [&::-moz-progress-bar]:bg-primary-200 [&::-webkit-progress-bar]:bg-gray-200 [&::-webkit-progress-value]:bg-primary-400"
                    max="100"
                    :value="item?.percentage!" />
                <progress
                    v-else
                    aria-labelledby="rating total"
                    class="block h-3 w-full overflow-hidden bg-gray-100 [&::-moz-progress-bar]:bg-gray-400 [&::-webkit-progress-bar]:bg-gray-200 [&::-webkit-progress-value]:bg-gray-200"
                    max="100"
                    :value="100" />
            </div>
        </div>
        <div class="flex w-full shrink">
            <NuxtLink
                :to="currentRoute('reviews') ? localePath({ path: '/shop' }) : localePath({ path: '/reviews/1' })"
                class="flex items-center justify-center gap-2 rounded-full bg-primary px-6 py-3 text-center text-sm font-bold text-white
                    transition-all hover:bg-primary-200 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 md:max-w-md">
                <span class="text-base">
                    {{ currentRoute('reviews') ? $t('orderOnline') : $t('review.seeAllreviews') }}
                </span>
                <Icon :icon="IconArrowRight" class="text-2xl lg:text-xl" :auto-align="false" />
            </NuxtLink>
        </div>
    </div>
</template>

<script setup lang="ts">
    import cloneDeep from 'lodash.clonedeep'
    import { reviews } from '~/store/reviews'
    import StarRating from '~/components/StarRating.vue'
    import Icon from '~/components/Icon.vue'
    import IconArrowRight from '@/assets/icons/arrow-right.svg'
    import IconStar from '@/assets/icons/star.svg'
    import type { CustomerReviewStatisticsOrderBuddy } from '~/types/orderbuddy-types'
    defineProps<CustomerReviewStatisticsOrderBuddy>()
    const localePath = useLocalePath()
    const route = useRoute()
    const currentRoute = (value: string) => route.fullPath.includes(value)
    const sortedRatingPercentages = cloneDeep(reviews().reviewStats?.ratingPercentages)?.sort((a, b) => b?.rating - a?.rating)
</script>
